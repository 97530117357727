<template>
  <van-popup v-model="showPicker" round position="bottom">
    <van-search
      v-if="useSearch"
      v-model="searchStr"
      placeholder="请输入搜索关键词"
      @input="throttleSearch"
    />
    <van-picker
      show-toolbar
      :columns="propColumns"
      @cancel="showPicker = false"
      @confirm="onConfirm"
    />
  </van-popup>
</template>
<script>
// 闭包节流函数
const throttle = (cb, gap) => {
  let timer;
  return function () {
    let _this = this;
    let args = arguments;
    if (!timer)
      timer = setTimeout(function () {
        timer = null;
        cb.apply(_this, args);
      }, gap);
  };
};
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    columns: {
      type: Array,
      default: () => []
    },
    useSearch: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return { searchStr: "", propColumns: [], throttle: null };
  },
  computed: {
    showPicker: {
      get() {
        return this.value;
      },
      set(v) {
        if (!v) this.searchStr = "";
        this.$emit("input", v);
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(v) {
        if (v)
          this.$nextTick(() => {
            this.propColumns = this.columns;
          });
      }
    }
  },
  methods: {
    throttleSearch() {
      if (!this.throttle) {
        this.throttle = throttle(() => {
          this.propColumns = this.columns.filter((e) =>
            e.text.includes(this.searchStr)
          );
        }, 500);
      }
      this.throttle();
    },
    onConfirm(v) {
      this.showPicker = false;
      this.$emit("confirm", v);
    }
  }
};
</script>
